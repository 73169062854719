import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const InfomaterialPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>Gastroenterologen Dres. Seutter & Flüeck Infomaterial</title>
        <meta
          name="description"
          content="Hier finden Sie das Infomaterial, das sie vor einem Termin bei uns durchsehen können"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Gastroenterologen Dres. Seutter & Flüeck Infomaterial"
        />
        <meta property="og:url" content="/infomaterial" />
        <meta
          property="og:description"
          content="Hier finden Sie das Infomaterial, das sie vor einem Termin bei uns durchsehen können"
        />
      </Helmet>
      <Layout>
        <div
          style={{
            backgroundImage:
              "linear-gradient(90deg, rgba(0,34,82) 0%, rgba(0,106,255) 100%), radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)",
            backgroundBlendMode: "multiply",
          }}
        >
          <div class="px-20p sm:px-10">
            <div class="max-w-900p mx-auto text-white py-35p sm:py-60p">
              <h1 class="font-bold text-15152 sm:text-24242">
                {frontmatter.title}
              </h1>
            </div>
          </div>
        </div>
        <div class="py-50p sm:py-80p px-20p sm:px-10">
          <div class="max-w-900p mx-auto space-y-35p sm:space-y-60p">
            {frontmatter.infomaterials.map((el) => {
              return (
                <div>
                  <h2 class="text-12121 sm:text-15152 font-bold text-primary pb-15p sm:pb-20p">
                    {el.title}
                  </h2>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={el.pdf.publicURL}
                    class="text-09697 sm:text-12121 font-medium text-lightblue"
                  >
                    PDF öffnen
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default InfomaterialPage;

export const query = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "infomaterial-page" } }) {
      frontmatter {
        title
        infomaterials {
          title
          pdf {
            publicURL
          }
        }
      }
    }
  }
`;
